<template>
  <div style="position: relative">
    <editor :api-key="key" :init="tinymceInit" v-model="valueEditor"/>
    <div class="editor-upload-img d-flex">
      <button title="Gửi kèm hình ảnh" type="button" tabindex="-1" class="tox-tbtn d-flex" id="question-upload-image" @click="handleUpload">
        <span class="tox-icon tox-tbtn__icon-wrap"><img src="~/mlearn/icons/modal/file.svg"></span>
      </button>
    </div>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
import { getToken } from '~/common/utils/auth'

export default {
  name: 'TinyEditor',
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    height: {
      type: [Number, String],
      default: 200
    },
    disable: {
      type: Boolean,
      default: false
    },
    hasUploadImage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: process.env.NUXT_ENV_TINYMCE_KEY,
      hasChange: false,
      hasInit: false,
      tinymceInit: {},
      actionCropper: false,
      fileCropper: '',
      imagePreview: '',
      imageUpload: ''
    }
  },
  computed: {
    valueEditor: {
      get () {
        return this.value
      },
      set (val) {
        if (!this.hasChange && this.hasInit) {
          this.$nextTick(() =>
            tinymce.activeEditor.setContent(val || '') // eslint-disable-line no-undef
          )
        }
      }
    }
  },
  created () {
    this.init()
  },
  deactivated () {
    this.destroyTinymce()
  },
  destroyed () {
    this.destroyTinymce()
  },
  methods: {
    init () {
      const self = this
      this.tinymceInit = {
        max_chars: 10000,
        placeholder: this.placeholder,
        height: this.height,
        menubar: false,
        branding: false,
        statusbar: false,
        forced_root_block: '',
        entity_encoding: 'raw',
        external_plugins: {
          tiny_mce_wiris: 'https://editor.colearn.vn/tinymce4/plugins/tiny_mce_wiris/plugin.min.js'
        },
        plugins: [
          'advlist autolink lists image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code'
        ],
        toolbar: 'tiny_mce_wiris_formulaEditor',
        toolbar_location: 'bottom',
        // toolbar: '',
        // valid_elements: 'p,br,img',
        init_instance_callback: (editor) => {
          if (self.value) {
            editor.setContent(self.value)
          }
          self.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup (editor) {
          // editor.on('input', function (e) {
          //   self.update(editor.getContent())
          // })
          editor.on('change', function (e) {
            self.update(editor.getContent())
          })
          editor.on('keydown', function (e) {
            if (tinymce.activeEditor.getContent().length + 1 > this.settings.max_chars) { // eslint-disable-line no-undef
              e.preventDefault()
              e.stopPropagation()
              return false
            }
            return true
          })
          editor.on('paste', function (e) {
            setTimeout(function () {
              let content = tinymce.activeEditor.getContent() // eslint-disable-line no-undef
              content = self.strip_tags(content, 'p,br')
              tinymce.activeEditor.setContent(content.trim().substring(0, 10000)) // eslint-disable-line no-undef
              self.update(content.trim().substring(0, 10000))
            }, 200)
          })
        }
      }
    },
    update (value) {
      if (this.disable) {
        this.$bvModal.show('modal-login')
        tinymce.activeEditor.setContent('') // eslint-disable-line no-undef
        this.$emit('input', '')
      } else {
        // remove p to br
        let valueReplace = value.replace(/<p>/gi, '<br/>')
        valueReplace = valueReplace.replace(/<\/p>/gi, '')
        valueReplace = valueReplace.replace(/&nbsp;/gi, ' ')
        valueReplace = valueReplace.replace(/<br \/>/gi, '<br/>')
        this.$emit('input', valueReplace)
      }
    },
    strip_tags (str, allowedTags) {
      let key = ''
      let allowed = false
      let matches = []
      let allowedArray = []
      let allowedTag = ''
      let i = 0
      let k = ''
      let html = ''

      // remove all attr
      str = str.replace(/<([a-z][a-z0-9]*)[^>]*?(\/?)>/gi, '<$1$2>')

      if (allowedTags) {
        allowedArray = allowedTags.match(/([a-zA-Z0-9]+)/gi)
      }
      str += ''

      matches = str.match(/(<\/?[\S][^>]*>)/gi)
      for (key in matches) {
        if (isNaN(key)) {
          continue
        }
        html = matches[key].toString()
        allowed = false
        for (k in allowedArray) {
          allowedTag = allowedArray[k]
          i = -1
          if (i !== 0) { i = html.toLowerCase().indexOf('<' + allowedTag + '>') }
          if (i !== 0) { i = html.toLowerCase().indexOf('<' + allowedTag + ' ') }
          if (i !== 0) { i = html.toLowerCase().indexOf('</' + allowedTag) }
          if (i !== 0) { i = html.toLowerCase().indexOf('<' + allowedTag + '/>') }
          if (i !== 0) { i = html.toLowerCase().indexOf('<' + allowedTag + ' />') }
          if (i === 0) {
            allowed = true
            break
          }
        }
        if (!allowed) {
          str = this.replacer(html, '', str)
        }
      }
      // remove p to br
      str = str.replace(/<p>/gi, '')
      str = str.replace(/<\/p>/gi, '<br/>')
      return str
    },
    replacer (search, replace, str) {
      return str.split(search).join(replace)
    },
    destroyTinymce () {
      if (tinymce) { // eslint-disable-line no-undef
        this.hasChange = false
        this.hasInit = false
        tinymce.remove() // eslint-disable-line no-undef
      }
    },
    handleUpload () {
      this.$emit('handle-upload')
    },
    changeFile () {
      const files = this.$refs.fileInput.files
      if (files.length === 0) { return }
      const x = 0
      const file = files[x]
      const size = file.size
      if (size === 0) {
        this.$notify({
          title: 'Lỗi',
          message: 'Ảnh lỗi',
          type: 'error'
        })
        return
      }
      const limit = 8
      if (size > limit * 1024 * 1024) {
        this.$notify({
          title: 'Lỗi',
          message: `Dung lượng không vượt quá ${limit}MB`,
          type: 'error'
        })
        return
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        this.fileCropper = e.target.result
        this.actionCropper = true
        this.$refs.cropper.replace(e.target.result)
      }
      reader.readAsDataURL(file)
      this.$refs.fileInput.value = ''
    },
    cropImage () {
      const copper = this.$refs.cropper.getCroppedCanvas()
      const image = copper.toDataURL()
      copper.toBlob((blob) => {
        this.actionCropper = false
        this.imagePreview = image
        this.imageUpload = blob
        this.uploadImg()
      }, 'image/png')
    },
    rotate (deg) {
      this.$refs.cropper.rotate(deg)
    },
    uploadImg () {
      if (!this.imageUpload) { return }
      const token = getToken()
      const formData = new FormData()
      formData.append('file', this.imageUpload)
      const config = {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
      axios.post(process.env.NUXT_ENV_BASE_API_UPLOAD + '/upload/qa/image', formData, config)
        .then((response) => {
          if (response.status === 200) {
            this.list_img = response.data.data.map(function (data) {
              return data.fileDownloadUri
            })
          }
        }).catch((err) => {
          this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
          this.list_img = []
        })
    },
    removeImage () {
      this.imagePreview = ''
      this.imageUpload = ''
      this.list_img = []
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.formula {
	position: absolute;
	z-index: 9;
	right: 8px;
	top: 8px;
	cursor: pointer;
}
.formula-mathjax {
  padding-bottom: 20px;
  .preview-mathjax {
    height: 140px;
    overflow-y: auto;
    background: #d0e3e9;
    margin: 0 0 15px 0;
    padding: 5px;
    p {
      font-weight: bold;
    }
	}
	a {
    border: 1px solid #e1e1e1;
    display: inline-block;
    padding: 6px 0 0 0;
    border-radius: 8px;
    width: 60px;
    box-shadow: 1px 2px 12px 0px #cccccc96;
    text-align: center;
    margin: 1px;
    height: 38px;
    vertical-align: middle;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .insertMathjax {
    margin-top: 15px;
    textarea {
      padding: 15px;
      width: calc(100% - 15px);
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      resize: none;
      min-height: 90px;
		}
	}
}
.editor-upload-img {
  position: absolute;
  bottom: 0;
  left: 42px;
  z-index: 9;
  border: none;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0;
  padding: 0 4px 0 4px;
  &::before{
    position: absolute;
    content: '';
    border-left: 1px solid #E2E2E2;
    width: 1px;
    height: 70%;
  }
  button {
    align-items: center;
    background: 0 0;
    border: 0;
    border-radius: 3px;
    box-shadow: none;
    color: #222f3e;
    display: flex;
    flex: 0 0 auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 34px;
    justify-content: center;
    margin: 2px 0 3px 0;
    outline: 0;
    overflow: hidden;
    padding: 0;
    text-transform: none;
    width: 34px;
    &:hover {
      background: #dee0e2;
      border: 0;
      box-shadow: none;
      color: #222f3e;
    }
  }
}
</style>
